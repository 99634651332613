export default {
    //手机号
    mobile: (rule, value, callback) => {
        if (!value) {
            callback(new Error("手机号不能为空"));
        } else {
            const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
            if (reg.test(value)) {
                callback();
            } else {
                callback(new Error("请输入正确的手机号"));
            }
        }
    },
    //名称
    heading: (rule, value, callback) => {
        // var reg = /^([\u4E00-\u9FA5A-Za-z])([\u4E00-\u9FA5A-Za-z0-9-_\s])+$/g;
        // var reg = /[\u4e00-\u9fa5_a-zA-Z]{1,32}/
        var reg =  /^[a-zA-Z\u4e00-\u9fa5]+$/;
        if (!reg.test(value)) {
            callback(new Error("请输入正确的名称格式"));
        } else {
            callback();
        }
    },
    //用户名
    username: (rule, value, callback) => {
        var reg = /^[a-zA-Z]{1}([a-zA-Z0-9]|[_-]){5,19}$/;
        if (!reg.test(value)) {
            callback(new Error("允许输入数字、英文字母、减号、下划线。开头只能为字母，字符长度6-20"));
        } else {
            callback();
        }
    },
    // 密码
    password:(rule, value, callback) => {
        var reg = /[\u4e00-\u9fa5/\s+/]/ig
        if (reg.test(value)) {
            callback(new Error("请输入除中文和空格的密码"));
        } else {
            callback();
        }
    },
}
