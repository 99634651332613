import request from '../request.js';
import public_request from '../public_request'

// 获取应用用户组列表
export function userGroupLists(data) {
    return request({
        url: '/setting/app-user-groups',
        method: 'GET',
        params: public_request(data)
    })
}
// 新增应用用户组
export function newUserGroup(data) {
    return request({
        url: '/setting/app-user-group',
        method: 'POST',
        data: public_request(data)
    })
}

// 编辑应用用户组
export function editUserGroup(data) {
    return request({
        url: '/setting/app-user-group',
        method: 'PUT',
        data: public_request(data)
    })
}

// 删除用户组
export function deleteUserGroup(data) {
    return request({
        url: '/setting/app-user-group',
        method: 'DELETE',
        data: public_request(data)
    })
}

// 获取应用用户组应用权限列表
export function applicationPermissionList(data) {
    return request({
        url: '/setting/app-user-group-permissions-application',
        method: 'GET',
        params: public_request(data)
    })
}
// 设置应用用户组应用权限
export function setApplicationPermission(data) {
    return request({
        url: '/setting/app-user-group-permissions-application',
        method: 'PUT',
        data: public_request(data)
    })
}
// 获取应用用户组页面权限列表
export function groupPageList(data) {
    return request({
        url: '/setting/app-user-group-permissions-page',
        method: 'GET',
        params: public_request(data)
    })
}
// 设置应用用户组页面权限
export function setGroupList(data) {
    return request({
        url: '/setting/app-user-group-permissions-page',
        method: 'PUT',
        data: public_request(data)
    })
}
// 获取应用用户组元素权限列表
export function pageElementPermission(data) {
    return request({
        url: '/setting/app-user-group-permissions-page-element',
        method: 'GET',
        params: public_request(data)
    })
}

// 获取应用用户组元素权限列表
export function setPageElementPermission(data) {
    return request({
        url: '/setting/app-user-group-permissions-page-element',
        method: 'put',
        data: public_request(data)
    })
}



// 获取应用用户组菜单权限列表
export function menusList(data) {
    return request({
        url: '/setting/app-user-group-permissions-menu',
        method: 'GET',
        params: public_request(data)
    })
}
// 设置应用用户组菜单权限
export function setMenus(data) {
    return request({
        url: '/setting/app-user-group-permissions-menu',
        method: 'PUT',
        data: public_request(data)
    })
}

// 获取应用用户组功能权限列表
export function groupFunction(data) {
    return request({
        url: '/setting/app-user-group-permissions-function',
        method: 'GET',
        params: public_request(data)
    })
}
// 获取应用用户组功能权限列表
export function setGroupFunction(data) {
    return request({
        url: '/setting/app-user-group-permissions-function',
        method: 'PUT',
        data: public_request(data)
    })
}