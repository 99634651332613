<template>
  <div>
    <el-card
      v-if="!showRoleEdit"
      class="card-box"
    >
      <div>
        <el-drawer
          ref="drawer"
          :title="form.id ? '编辑用户组' : '添加用户组'"
          :before-close="handleClose"
          :visible.sync="showAddOrEdit"
          direction="rtl"
          custom-class="demo-drawer"
          size="683px"
        >
          <div class="drawer-content">
            <el-form
              ref="form"
              :model="form"
              :rules="rules"
              label-width="80px"
              label-position="top"
            >
              <el-form-item
                label="组名"
                prop="name"
              >
                <el-input
                  v-model="form.name"
                  autocomplete="off"
                />
              </el-form-item>
              <el-form-item
                label="CRM账号可使用数量上限"
              >
                <el-input
                  v-model="form.staffOnlineMax"
                  autocomplete="off"
                  oninput="value=value.replace(/[^\d]/g,'')"
                />
              </el-form-item>
              <el-form-item
                label="CRM外呼坐席可使用数量上限"
              >
                <el-input
                  v-model="form.callSeatMax"
                  autocomplete="off"
                  oninput="value=value.replace(/[^\d]/g,'')"
                />
              </el-form-item>
              <el-form-item
                label="备注"
                prop="remarks"
              >
                <el-input
                  v-model="form.remarks"
                  placeholder="在这里输入备注便于记忆哦！"
                  autocomplete="off"
                  type="textarea"
                  :rows="4"
                  maxlength="128"
                  show-word-limit
                />
              </el-form-item>
            </el-form>
            <div class="drawer-footer">
              <el-button
                type="primary"
                @click="submitAddOrEdit"
              >
                {{
                  form.name ? "保 存" : "创 建"
                }}
              </el-button>
              <el-button @click="handleClose">
                取 消
              </el-button>
            </div>
          </div>
        </el-drawer>

        <el-button
          type="primary"
          size="medium"
          @click="addOrEditDrawer"
        >
          添加用户组
        </el-button>
        <el-table
          v-loading="loading"
          v-tableHeight="{bottomOffset: 80}"
          :header-cell-style="styleObj"
          :data="tableData"
          style="width: 100%;margin-top:10px"
          border
          height="100px"
          :row-style="{height: '0'}"
          :cell-style="{padding: '0'}"
        >
          <el-table-column
            type="index"
            width="55"
            label="序号"
          />
          <el-table-column
            prop="name"
            label="组名"
          />
          <el-table-column
            prop="creator"
            label="创建人"
          />
          <el-table-column
            prop="remarks"
            label="备注"
          />
          <el-table-column
            label="操作"
            width="180"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="addOrEditDrawer(scope.row)"
              >
                编辑
              </el-button>
              <el-button
                type="text"
                @click="editRole(scope.row)"
              >
                应用权限
              </el-button>
              <el-popconfirm
                :title="'确认删除 ' + scope.row.name + '吗？'"
                @confirm="handleDelete(scope.row)"
              >
                <el-button
                  slot="reference"
                  style="color:#D9001B;margin-left:10px"
                  type="text"
                >
                  删除
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align:right;margin-top:10px">
          <el-pagination
            :current-page="currentPage"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="userTotal"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </el-card>    
    <group-permissions
      v-else
      :group-role="groupRole"
      @back="back"
    />
  </div>
</template>

<script>
// import {
//   userSettingList,
//   userEnable,
//   getSettingUserDetail,
//   getRolesSelect,
//   addUser,
//   editUser,
//   delUser,
// } from "@/api/setting/userSetting";
import regular from "@/functions/regular";
import {
  userGroupLists,
  newUserGroup,
  editUserGroup,
  deleteUserGroup,
} from "@/api/setting/user-group";

export default {
  components: {
    "group-permissions": () => import("./group-permissions"),
  },
  data() {
    return {
      styleObj: {
        background: "#f5f7fa",
      },
      showAddOrEdit: false,
      tableHeight: 50,
      tableData: [
        {
          id: 1,
          name: "助贷CRM免费用户组",
          creator: "王思聪",
          remarks: "备注你的备注备注你的备注备注你的备注备注你的备注备注",
        },
      ],
      userTotal: 0,
      currentPage: 0,
      form: {
        name: "",
        remarks: "",
        staffOnlineMax: '',
        callSeatMax: ''
      },
      roleList: [],
      search: {
        pageNo: 1,
        pageSize: 10,
      },
      loading: false,
      rules: {
        name: [
          { required: true, message: "请输入用户组名称", trigger: "blur" },
          { min: 1, max: 32, message: "长度是1-32个字符之间", trigger: "blur" },
          { validator: regular.heading, trigger: "blur" },
        ],
      },
      showRoleEdit: false,
      groupRole:{}
    };
  },
  created() {
    this.getTableList();
  },
  methods: {
    back(val) {
      this.showRoleEdit = val;
    },
    getTableList() {
      userGroupLists(this.search).then((res) => {
        this.loading = true;
        if (res.code == 0) {
          this.tableData = res.data.list;
          setTimeout(() => {
            this.loading = false;
          }, 500);
        }
      });
    },
    // getRoles() {},
    handleSizeChange(val) {
      this.search.pageSize = val;
      this.getTableList();
    },
    handleCurrentChange(val) {
      this.search.pageNo = val;
      this.currentPage = val;
      this.getTableList();
    },
    addOrEditDrawer(row) {
      this.showAddOrEdit = true;
      console.log(row)
      if (row.id) {
        this.$nextTick(() => {
          this.form = JSON.parse(JSON.stringify(row));
        });
      } else {
        for (const key in this.form) {
          this.form[key] = ''
        }
      }
    },
    handleClose() {
      console.log(this.form)
      this.form.id = "";
      this.$nextTick(() => {
        this.$refs.form.resetFields();
      });
      console.log(this.form);
      this.showAddOrEdit = false;
    },
    // 创建添加角色或者编辑角色
    submitAddOrEdit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          // 表单存在id就是编辑否则就是新增
          if (this.form.id) {
            const form = {
              groupId: this.form.id,
              ...this.form
            }
            editUserGroup(form).then((res) => {
              if (res.code == 0) {
                this.$message.success("修改用户组成功");
                this.handleClose();
                this.getTableList();
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            newUserGroup(this.form).then((res) => {
              if (res.code == 0) {
                this.$message.success("添加用户组成功");
                this.handleClose();
                this.getTableList();
              } else {
                this.$message.error(res.message);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    // // 做删除操作
    handleDelete(row) {
      console.log(row);
      deleteUserGroup({ groupId: row.id }).then((res) => {
        if (res.code == 0) {
          this.$message.success("用户删除成功");
          // 这里做删除数据最后一项造成的bug
          if (
            Math.floor((parseInt(this.userTotal) - 1) / this.search.pageSize) ==
              (parseInt(this.userTotal) - 1) / this.search.pageSize &&
            Math.ceil(
              parseInt(this.userTotal) / parseInt(this.search.pageSize)
            ) -
              parseInt(this.search.pageNo) <
              1
          ) {
            this.search.pageNo = this.search.pageNo - 1;
          }
          // 重新加载数据
          this.getTableList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    editRole(row) {
      console.log("显示权限组件");
      this.showRoleEdit = true;
      // console.log(row)
      this.groupRole = row
    },
  },
};
</script>

<style scoped lang="less">
.card-box {
  margin: 10px;
  height: calc(100vh - 170px);
}
/deep/ .el-drawer:focus {
  outline: none;
}
/deep/ .el-drawer__header {
  margin: 0;
  padding: 0;
  margin: 20px 20px 20px 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc;
  // padding: 0;
  span {
    outline: none;
    font-weight: 600;
    color: #000;
  }
}
.drawer-content {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
  .el-form {
    flex: 1;
    height: 0;
    overflow: auto;
  }
  .drawer-footer {
    display: flex;
    justify-content: center;
    // .el-button {
    //   // flex: 1;
    // }
  }
}
/deep/ .el-drawer__body {
  overflow-y: scroll;
}
</style>
